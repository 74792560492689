<template>
    <div>
		<a-form-model-item :label="`Use Custom Navigation?`" class="mb-4">
			<a-switch v-model="navigation.visible"></a-switch>
		</a-form-model-item>
        <a-modal v-model="dialog.show" :title="dialog.title" @ok="handleDialogOK" @cancel="closeDialog">
            <a-form-model :model="menuItem" ref="menuItem">
                <a-row :gutter="16">
                    <a-col :span="18">
                        <a-form-model-item :label="`Enter menu name`" prop="label" :rules="{required:true,message:'Name is required'}" class="mb-1">
                            <a-input v-model="menuItem.label" :placeholder="dialog.placeholder" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item :label="menuItem.important ? 'Menu Style: Button' : 'Menu Style: Link'" prop="important" class="mb-1">
                            <a-switch v-model="menuItem.important" />
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-form-model-item :label="`Menu type`" prop="type" :rules="{required:true,message:'Type is required'}" class="mb-1">
                    <a-select v-model="menuItem.type" :options="linkType" />
                </a-form-model-item>
				<template v-if="menuItem.type === 'link'">
					<a-form-model-item :label="`External Link (URL)`" prop="link" class="mb-1">
                        <a-input v-model="menuItem.link" placeholder="https://...." />
                    </a-form-model-item>
				</template>
                <template v-else-if="menuItem.type === 'section'">
                    <a-form-model-item :label="`Select a Section/Modal`" prop="link" class="mb-1">
                        <a-select v-model="menuItem.link" :options="pageSectionsOptions" />
                    </a-form-model-item>
                </template>
                <template v-else-if="menuItem.type === 'page'">
					<a-form-model-item :label="`Select a Page`" prop="link" class="mb-1">
                        <a-select v-model="menuItem.link" :options="pagesOptions" />
                    </a-form-model-item>
                </template>
            </a-form-model>

        </a-modal>
		<template v-if="navigation && navigation.visible">
			<draggable :list="navigation.items"  handle=".handle" class="nav-menu-items-wrapper" :delay-on-touch-only="true" :delay="100">
				<a-collapse v-for="(item,idx) in navigation.items" :key="idx" :defaultActiveKey="idx === 0 ? '1' : '0'" class="nav-menu-items">
					<a-collapse-panel key="1" :header="item.label" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">
						<div slot="extra">
							<a-icon class="handle mr-2" type="menu"/>
							<a-icon class="hover:text-primary mr-2" type="edit" @click.stop="editItemDialog(item)" />
							<a-icon class="hover:text-primary" type="delete" @click.stop="deleteItem(item.id)" />
						</div>
						<template v-if="item.children">
							<draggable :list="item.children"  handle=".handle2" :delay-on-touch-only="true" :delay="100">
								<div v-for="(childItem,idx) in item.children" :key="idx" class="px-3 py-2 dF jSB" style="background:#efefef; border-bottom:1px solid #ddd">
									<div>
										{{childItem.label}}
									</div>
									<div >
										<a-icon class="handle2 mr-2" type="menu" style="font-size:11px;cursor:grab;" />
										<a-icon class="hover:text-primary mr-2 cursor-pointer" type="edit" @click.stop="editItemDialog(childItem,item.id)" style="font-size:11px" />
										<a-icon class="hover:text-primary cursor-pointer" @click.stop="deleteItem(childItem.id,item.id)" type="delete" style="font-size:11px" />
									</div>
								</div>
							</draggable>
						</template>
						<a-button class="mt-2" icon="plus" @click="addItemDialog(item.id)" ghost type="primary" style="border:0;">Add sub menu</a-button>


					</a-collapse-panel>
				</a-collapse>
				<div  class="py-2">
					<a-button icon="plus" @click="() => addItemDialog()" ghost type="primary">Add menu item</a-button>
				</div>

			</draggable>
		</template>

    </div>
</template>

<script>

import draggable from 'vuedraggable'
export default {
    props:{
        value:{
            type:Object,
            required:true,
            default: {}
        },
		project:{
			type:Object,
			default:{}
		}
    },
	components:{draggable},
    data(){
        return {
			dialog:{
				show:false,
				edit:false,
				id:'',
				parent:'',
				title:'',
			},
            menuItem:{
				label:'',
				link:'',
				section:'',
				type:'page',
				important:false,
			},
			navigation:{
				visible:false,
				items:[]
			}
        }
    },
    computed:{
		linkType(){
            let types =  [{label:'External Link',value:'link'},{label:'Section',value:'section'},{label:'Page',value:'page'}]
            // if (!this.$store.getters.isLanding) types.push({label:'Goto Page',value:'page'})
            return types
        },
        pageSectionsOptions(){
			let sections = JSON.parse(JSON.stringify(this.$store.getters.pageSectionsOptions))
			sections.forEach(x => {
				x.value = '#sect-' + x.value
			})
            return sections
        },
        pagesOptions(){
            return this.project.pages.map(page => ({
				label:page.name,
				value:'/'+page.slug
			}))
        },
    },
	methods:{
		addItemDialog(parent){
            this.dialog.title = 'Add Menu'
            this.dialog.parent = parent
            this.dialog.show = true
        },
        deleteItem(id,parent){
            console.log('DELETE', id)
            if (parent){
                if (!this.navigation.items.find(x => x.id === parent)) return this.$message.error('could not find parent')
                let findIndex = this.navigation.items.findIndex(x => x.id === parent)
                if (findIndex > -1 && this.navigation.items[findIndex] && this.navigation.items[findIndex].children && this.navigation.items[findIndex].children.length){
                    let foundChildIndex = this.navigation.items[findIndex].children.findIndex(x=> x.id === id)
                    if (foundChildIndex > -1){
						let navigation = JSON.parse(JSON.stringify(this.navigation))
						navigation.items = navigation.items[findIndex].children.splice(foundChildIndex,1)
						this.navigation = navigation
                    }
                }
            } else {

                let findIndex = this.navigation.items.findIndex(x => x.id === id)
                console.log('FINDINDEX', findIndex)
                if (findIndex > -1){
                    this.navigation.items.splice(findIndex,1)
                }
            }
        },
        editItemDialog(item,parent){
            this.dialog.title = `Edit Item: ${item.label}`
            this.dialog.show = true
            this.dialog.id = item.id
            if (parent){
                this.dialog.parent = parent
            }

            this.menuItem = JSON.parse(JSON.stringify(item))
        },
		handleDialogOK(){
            const err = (msg = 'Error occured while trying to update menu') => {
                this.$message.error(msg)
                return this.closeDialog()
            }
            this.$refs.menuItem.validate( async res => {
                console.log('RESSSSSS',this.menuItem,this.dialog)
                if (this.dialog.id){

                    let found = this.navigation.items.find(x => x.id === this.dialog.id)
                    if (this.dialog.parent) {

                        found = this.navigation.items.find(x => x.id === this.dialog.parent)
                        if (found && found.children && found.children.length){
                            console.log('FOUND PARENT', found)
                            found = found.children.find(x => x.id === this.dialog.id)
                        }

                    }

                    if (!found) return err()

                    console.log('FOUND', found, this.menuItem)

                    Object.entries(this.menuItem).forEach( ([key,value]) => {
                        found[key] = this.menuItem[key]
                    })

                } else {

                    let menuItem = JSON.parse(JSON.stringify(this.menuItem))
                    menuItem.id = Date.now() + '-' + ((Math.floor(Math.random() * 10)) + '' + (Math.floor(Math.random() * 10)))
                    if (this.dialog.parent){
                        menuItem.id = this.dialog.parent + '-' + ((Math.floor(Math.random() * 10)) + '' + (Math.floor(Math.random() * 10)))
                        let navigation = JSON.parse(JSON.stringify(this.navigation))
						navigation.items.forEach(item => {
                            if (item.id === this.dialog.parent){
                                if (!item.children) item.children = []
                                item.children.push(menuItem)
                            }
                        })
						this.navigation = navigation
                    } else {
                        this.navigation.items.push(menuItem)
                    }
                }

                this.closeDialog()

            })
        },
        closeDialog(){
            this.dialog = {
                show:false,
                edit:false,
                id:'',
                parent:'',
                title:'',
            }
            this.menuItem = {
                label:'',
                link:'',
                section:'',
                type:'page',
                important:false,
            }
        },
	},
    watch:{
        navigation:{
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        },
    },
    created(){
		this.navigation = this.value
    },
    mounted(){

    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;

    }
</style>
